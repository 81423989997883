import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: "#e91e63",
				secondary: "#1d353ee3",
				background: "#1d353ee3",
				error: "#e91e63",
				green : "#68c56b",
			},
			dark: {
				primary: "#e91e63",
				secondary: "#1d353ee3",
				background: "#1d353ee3",
			  },
		}
	}
});
