<template>
	<div class="text-center overflow-y-auto" v-if="$store.state.isadmin" >
		<v-menu offset-y class="text-center overflow-y-auto">
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					color="primary"
					dark
					v-bind="attrs"
					v-on="on"
				>
					ADMIN
				</v-btn>
			</template>
			<v-list
				class="overflow-y-auto"
				dense
				>
				<v-list-item
					v-for="(item, index) in $store.state.admin"
					:key="index"
					@click="switch_user(item)"
					link
					style="min-height:30px!important"
					
				>
					<v-list-item-title class="text-caption ma-0" >{{ item.title }}</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>


<script>
	export default {
		data: () => ({
		}),
		methods:{
			switch_user(item) {
				this.$store.dispatch('logOut')
				this.$store.commit('setUserEmail', item.email)
				this.$store.dispatch('logIn')
				this.$router.push('/admin')
			}
		},
	}
</script>