import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		logged_in: false,
		loggin_loading: false,
		user_email:'',
		user_password:'',
		profile : {
					"ID": 0,
					"Name": "",
					"Email": "",
					"Password": "",
					"CompanyName": "",
					"LastLogin": "",
					"Config": {	},
					"Componets": [	],
					"CreatedAt": "",
					"UpdatedAt": ""
			
		},
		modules: [],

		// new module registration progress for wizzard.
		module_registration : {
			created : false,
			authrized : false,
			payment: false,
			company_details : false,
		},

		admin: [],
		isadmin : false,
		allegro : {
			token_expired : true,
			prices_not_set : true,
		},
		dialog : {
			visible: false,
			heading: 'Błąd',
			message: 'Uwaga wystąpił jakiś błąd.',
		},
	},
	mutations: {
		logOut(state){
				// state.profile = {}  //uncomment for production
				state.logged_in = false
				state.modules = []
				state.admin = []
				state.isadmin = false
				state.user_email = ''
				state.user_password = ''

				axios.get(process.env.VUE_APP_API_URL + '/logout', {})
				.then(function (response) {
					
				})
				.catch(function (error) {
					console.log(error);
					commit('openDialog', {heading: 'Błąd ', message: 'Uwaga: Wystąpił błąd w połączeniu z serwerem.'})
				});
		},
		logIn(state, data){
			
			// console.log(data.modules)

			//setup modules object for use with menu
			var ms = []
			if (data.modules != null){
				for (var i = 0; i < data.modules.length; i++) {
					var m = data.modules[i]

					var ic = 'mdi-help-box'
					if (m.ModuleType=='allegro'){ic = 'mdi-alpha-a-box'	} 
					if (m.ModuleType=='general'){ ic = 'mdi-alpha-g-box' }
					if (m.ModuleType=='ceneo'){ ic = 'mdi-alpha-c-box' }
					if (m.ModuleType=='stock'){ ic = 'mdi-alpha-s-box' }
					if (m.ModuleType=='bclink'){ ic = 'mdi-connection' }

					ms[i] = {
						title: m.ModuleName,
						icon: ic,
						to:'/' + m.ModuleType + '/' + m.ModuleId,
						status : m.Status,
						module_id : m.ModuleId,
					}	
				}
			}

			//setup admin object 
			var ad = []
			state.isadmin = false

			if (data.admin != null){
				state.isadmin = true

				for (var i = 0; i < data.admin.length; i++) {
					var a = data.admin[i]
					console.log(a);
					ad[i] = {
						title: a.Name + ' ('+ a.Email +')',
						email : a.Email,
					}	
				}
			}
			

			data.profile.Componets = m
			state.profile = data.profile
			state.modules = ms
			state.admin = ad

			if (data.profile.Email != '')
			{
				state.logged_in = true
			}
		},
		allegroTokenRenewed(state){
			state.allegro.token_expired=false
		},
		setUserEmail (state, newVal){
			state.user_email = newVal
		},
		setUserPassword (state, newVal){
			state.user_password = newVal
		},
		closeDialog(state ){
			state.dialog.visible = false
		},

		openDialog(state , data){
			state.dialog.visible = true
			state.dialog.heading = data.heading
			state.dialog.message = data.message
		},

		setModuleRegistration(state, mr ){
			this.module_registration = mr
		}

	},
	actions: {
		logIn({commit, state}) {
			axios.post(process.env.VUE_APP_API_URL + '/', {
				email: state.user_email,
				password: state.user_password
			})
			.then(function (response) {
				if (response.data.error != null){
					commit('openDialog', {heading: 'Błąd Logowania', message: 'Nieprawidłowe hasło lub użytkownik.'})
				}

				commit('logIn', response.data)

			})
			.catch(function (error) {
				console.log(error);
				commit('openDialog', {heading: 'Błąd Logowania', message: 'Uwaga: Nieprawidłowe hasło lub użytkownik albo wystąpił błąd w połączeniu z serwerem.'})
			});
		},
		getProfile({commit}) {
			
			console.log("CHECK IF LOGGED IN")
			// check if logged in
			axios.get(process.env.VUE_APP_API_URL + '/profile')
			.then(function (response) {
				commit('logIn', response.data)
			})
			.catch(function (error) {
				console.log(error);
			});
		}
	},
	modules: {
	}
})
