<template>
  <v-app id="inspire" >	
	<dialogue/>

	<!-- login/register -->
	<v-sheet  
		fill-height  
		height="100%"
		width="100%" 
		color="#1d353ee3" 
		v-if=!$store.state.logged_in>
		
		<register 
			v-if=show_reg 
			:track=track
		/>

		<login v-if=!show_reg />
		
		<v-footer
			dark
			padless
			color="#1d353ee3" 
			absolute
		
		>
			<v-col
			class="text-center"
			cols="12"
			simple
			>
		{{ new Date().getFullYear() }}  <strong>BotCen</strong>
		</v-col>
 	 </v-footer>
  </v-sheet>

	<!-- dashboard frame  -->
	<div v-if=$store.state.logged_in>
	<v-navigation-drawer
	  v-model="drawer"
	  app
	  dark
	  color="#1f3740"
	>
		<v-list-item >
			<v-list-item-content >
			<v-list-item-title class="title" >
				BOT<b class="primary--text">CEN</b>
			</v-list-item-title>
			<v-list-item-subtitle>
				automating ecommerce
			</v-list-item-subtitle>
			</v-list-item-content>
		</v-list-item>

	  <v-divider></v-divider>


		<v-list dense nav>
			<v-subheader>Usługi</v-subheader>
			<v-list-item
			v-for="item in $store.state.modules"
			:key="item.title"
			:to="item.to"
			link
			>
			<v-list-item-icon>
				<v-icon>{{ item.icon }}</v-icon>
			</v-list-item-icon>

			<v-list-item-content>
				<v-list-item-title>{{ item.title }}</v-list-item-title>
			</v-list-item-content>
			</v-list-item>


			<v-list-item
			link
			to="/newmodule"
			>
			<v-list-item-icon>
				<v-icon>mdi-plus-circle</v-icon>
			</v-list-item-icon>

			<v-list-item-content>
				<v-list-item-title>Dodaj Usługę</v-list-item-title>
			</v-list-item-content>
			</v-list-item>
	  </v-list>


	  <v-list
		dense
		nav
	  >
	  	<v-subheader></v-subheader>

		<v-list-item
		  v-for="item in items"
		  :key="item.title"
		  :to="item.to"
		  link
		>
		  <v-list-item-icon>
			<v-icon>{{ item.icon }}</v-icon>
		  </v-list-item-icon>

		  <v-list-item-content>
			<v-list-item-title>{{ item.title }}</v-list-item-title>
		  </v-list-item-content>
		</v-list-item>

		<!-- <v-list-item
		  v-if="show_pricelist"
		  to="/prices"
		  link
		>
		  <v-list-item-icon>
			<v-icon>mdi-credit-card</v-icon>
		  </v-list-item-icon>

		  <v-list-item-content>
			<v-list-item-title>Cennik</v-list-item-title>
		  </v-list-item-content>
		</v-list-item> -->

		<v-list-item

		  href="mailto:botcenowy@gmail.com?subject=Wsparcie BotCen"
		  link
		>
		  <v-list-item-icon>
			<v-icon>mdi-help-box</v-icon>
		  </v-list-item-icon>

		  <v-list-item-content>
			<v-list-item-title>Pomoc</v-list-item-title>
		  </v-list-item-content>
		</v-list-item>

	  </v-list>
	  		<v-footer
				dark
				padless
				color="#1d353ee3" 
				absolute
			
			>
				<v-col
				class="text-center"
				cols="12"
				simple
				>
					{{ new Date().getFullYear() }} <a href="https://botcen.pl" target="blank"><strong >BotCen</strong> </a>
				</v-col>
			</v-footer>
	</v-navigation-drawer>

	<v-app-bar  dark app color="#1d353ee3">
	  <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
	 	 <img :src="$store.state.profile.Config.logo" style="max-height:42px; max-width:180px; margin-right:20px; background: white;">
	  <v-toolbar-title>{{$store.state.profile.CompanyName}}</v-toolbar-title>
	  

	  <v-spacer></v-spacer>
	  <adminselect/>
	  <v-btn icon>
		<v-icon>mdi-account-circle</v-icon>
	  </v-btn> 
	  <v-toolbar-title><span class="font-weight-black">{{$store.state.profile.Name}}</span></v-toolbar-title>


	  <v-btn icon>
		<v-icon>mdi-dots-vertical</v-icon>
	  </v-btn>

	<!-- <v-btn depressed color="#1d353ee3" @click="$store.commit('logOut')" to="/"> -->
	<v-btn depressed color="#1d353ee3" @click="logout" to="/">
		Logout
	</v-btn>

	</v-app-bar>

	<v-main class="overflow: auto">
	<!-- If using vue-router -->
	  <router-view></router-view>
	</v-main>
	</div>

  </v-app>
</template>

<script>
	// import {mapMutations} from "vuex"
	import Dialogue from './components/Dialogue.vue'
	import AdminSelect from './components/AdminSelect.vue'
	import Register from './components/Register.vue'
	import Login from './components/Login.vue'

	export default {
		components :{
			'dialogue': Dialogue,
			'adminselect' : AdminSelect,
			'register' : Register,
			'login' : Login,
		},
		data: () => ({ 
			fb_conversion : false,
			drawer: null,
			register : null,
			track: null,
			items: [
				// { title: 'Płatnosci', icon: 'mdi-credit-card-outline', to: '/payments'  },
				// { title: 'Ustawienia', icon: 'mdi-cog-box' , to: '/settings' },
			]
		}),

		mounted() {
			this.checkReg ()
			const { actions } = this.$store; 
			this.$store.dispatch('getProfile')

			// check window size and hide sidebar
			if (window.innerWidth < 1650){
				this.drawer = false
			}
			console.log(window.innerWidth)
		},

		computed: {

			show_reg : function () {
				if (this.register != null){
					return true
				} 
				
				return false
			},

			show_pricelist : function (){
				if (this.$store.state.profile.Config.hide_pricelist != null){
					return false
				} 
				
				return true	
			},


		},
		methods: {
			validate () {
				this.$refs.form.validate()
			},

			checkReg () {
				let uri = window.location.search.substring(1); 
				let params = new URLSearchParams(uri);
				this.register = params.get("register")
				this.track = params.get("ref")
			},
			logout () {
				this.$store.commit('logOut')
				this.$router.push({ path: '/' })
			}
		}
		
	}
</script>