<template>
	<v-container height="100%" fill-height  >
		<v-card
			class="mx-auto pa-10"
			min-width="330px"
			outlined
			dark
			color="#1d353ee3"
		>
			<v-img
				contain
				class="mx-auto mb-15"
				width="200"
				src="@/assets/logo.svg"
			></v-img>
				<v-form
					ref="form"
					class="mb-5"
				>
						<v-text-field
							v-model="user_email"
							label="e-mail"
							filled
							outlined
							required
						></v-text-field>

						<v-text-field
						:type="false ? 'text' : 'password'"
							v-model="user_password"
							label="hasło"
							required
							filled
							outlined
						></v-text-field>
						
						<v-btn
							color="primary"
							class="mr-4"
							@click="$store.dispatch('logIn')"
							:loading="$store.state.loggin_loading"
						>
							Login
						</v-btn>
				</v-form>

			<v-container class="pa-0">
				<v-btn depressed plain x-small href="?register">
					utwórz konto
					<v-icon  right>mdi-plus-circle</v-icon>
					
				<!-- </a> -->
				</v-btn>
				<v-btn depressed plain x-small href="mailto:botcenowy@gmail.com?subject=odzyskaj hasło botcen">
					odzyskaj hasło 
					<v-icon  right>mdi-help-circle</v-icon>
				<!-- </a> -->
				</v-btn>
			</v-container>
		</v-card>
	</v-container>
</template>


<script>
	export default {
		computed: {
			user_email : {
				get: function() {
					return this.$store.state.user_email
				},
				set: function (newVal){
					this.$store.commit('setUserEmail', newVal)
				}
			},

			user_password : {
				get: function() {
					return this.$store.state.user_password
				},
				set: function (newVal){
					this.$store.commit('setUserPassword', newVal)
				}
			},
		}
	}
</script>
