<template>
	<v-container height="100%" fill-height   >
		<v-card
			class="mx-auto pa-7"
			min-width="400px"
			outlined	
		>
			<h1>Utwórz Konto</h1>
			<h3>Uruchom automatyzacje w parę minut</h3>

				<form class="mt-5" >

					<v-text-field
						ref="email"
						v-model="email"
						:rules="email_rules"
						clearable
						outlined
						label="email"
						required
					></v-text-field>
					
					<v-text-field
						ref="pass"
						v-model="pass"
						:rules="pass_rules"
						:counter="8"
						clearable
						outlined
						label="hasło"
						required
						:type="false ? 'text' : 'password'"
					></v-text-field>


					<v-text-field
						ref="pass_repeat"
						v-model="pass_repeat"
						:rules="[pass_match_rule]"
						clearable
						outlined
						label="powtórz hasło"
						required
						:type="false ? 'text' : 'password'"
					></v-text-field>


					<v-text-field
						ref="name"
						v-model="name"
						:rules="name_rules"
						clearable
						outlined
						label="imię"
						required
					></v-text-field>


					<v-text-field
						ref="company_name"
						v-model="company_name"
						:rules="company_rules"
						clearable
						outlined
						label="nazwa firmy"
						required
					></v-text-field>


					<v-text-field
						ref="phone"
						v-model="phone"
						:rules="phone_rules"
						clearable
						outlined
						label="telefon"
						required
					></v-text-field>

					<v-text-field
						ref="logo_url"
						v-model="logo_url"
						clearable
						outlined
						label="logo url ( opcjonalne )"
					></v-text-field>


					<v-btn
						class="mr-4"
						@click="registerAccount"
						color="primary"
						:disabled="invalid"
					>
						Utwórz konto
					</v-btn>

				</form>
				<div v-if="fb_conversion">
					<iframe
					:src="`fb.html`"
					width="0"
					height="0"
					frameborder="0" >
					</iframe>
				</div>
		</v-card>
	</v-container>	
</template>


<script>
  export default {
	props: [
		'track',
	],
	methods: {

		registerAccount () {
			axios.post(process.env.VUE_APP_API_URL + '/register', {
				email: this.email,
				password: this.pass,
				name : this.name,
				company_name : this.company_name,
				phone: this.phone,
				logo_url: this.logo_url,
				ref : this.track,
				
			})
			.then(response => {
				if (response.data.created != null){

					this.fb_conversion = true;

					// give a few secs for conversion to happen
					setTimeout(() =>  { this.$store.dispatch('getProfile');  window.location.replace('/app/'); }, 2500);

					// this.$store.dispatch('getProfile')
					// this.$router.push({ path: '/' })
					// window.location.replace('/app/');
				} else {
					this.$store.commit('openDialog', {heading: 'Błąd Rejestracji', message: 'Uwaga: wystąpił błąd.'})
				}
			})
			.catch( error => {
				console.log(error);
				this.$store.commit('openDialog', {heading: 'Błąd Rejestracji', message: 'Uwaga: wystąpił błąd.'})
			});
		},


	},

	computed: {
		pass_match_rule() {
			return () => (this.pass === this.pass_repeat) || 'Hasła się różnią'
		},

		invalid () {
			if (this.is_mounted){
				if(
					this.$refs.email.valid &&
					this.$refs.pass.valid &&
					this.$refs.pass_repeat.valid &&
					this.$refs.name.valid &&
					this.$refs.company_name.valid &&
					this.$refs.phone.valid 
				){
					return false
				}
			}
			return true
		}
	},

	mounted() {
		this.is_mounted = true
	},

	data: () => ({ 
		fb_conversion : false,
		is_mounted : false,
		valid: false,
		logo_url:'',
		email: '',
		email_rules: [
			v => !!v || 'E-mail jest wymagany',
			v => /.+@.+/.test(v) || 'E-mail musi być poprawny',
	 	],
		pass: '',
		pass_rules: [
			v => !!v || 'Hasło jest wymagane',
			v => (v && v.length >= 8) || 'Hasło musi mieć minimum 8 liter',
	 	],
		pass_repeat: '',
		pass_rules_match: [
			v => !!v || 'Hasło jest wymagane',
	 	],
		name: '',
		name_rules: [
			v => !!v || 'Imię jest wymagane',
	 	],
		company_name: '',
		company_rules: [
			v => !!v || 'Nazwa Firmy jest wymagana',
	 	],
		phone : '',
		phone_rules: [
			v => !!v || 'Numer Telefonu jest wymagany',
			v => (v && v.length >= 9) || 'Numer Telefonu  musi mieć minimum 9 cyfr',
	 	],
	}),
  }
</script>