import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [

	{
		path: '/',
		name: 'home',
		component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
	},
	{
		path: '/newmodule',
		name: 'newmodule',
		component: () => import(/* webpackChunkName: "about" */ '../views/NewModule.vue')
	},
	{
		path: '/newmodule/:module_id',
		name: 'newmodule',
		component: () => import(/* webpackChunkName: "about" */ '../views/NewModule.vue')
	},
	{
		path: '/allegro/:module_id',
		name: 'allegro',
		component: () => import(/* webpackChunkName: "about" */ '../views/Allegro.vue')
	},
	{
		path: '/allegro/:module_id/:product_id',
		name: 'allegro product',
		component: () => import(/* webpackChunkName: "about" */ '../views/AllegroProduct.vue')
	},
	{
		path: '/general/:module_id',
		name: 'general',
		component: () => import(/* webpackChunkName: "about" */ '../views/General.vue')
	},
	{
		path: '/general/:module_id/:product_id',
		name: 'general product',
		component: () => import(/* webpackChunkName: "about" */ '../views/GeneralProduct.vue')
	},
	{
		path: '/ceneo/:module_id',
		name: 'ceneo',
		component: () => import(/* webpackChunkName: "about" */ '../views/Ceneo.vue')
	},
	{
		path: '/ceneo/:module_id/:product_id',
		name: 'ceneo product',
		component: () => import(/* webpackChunkName: "about" */ '../views/CeneoProduct.vue')
	},
	{
		path: '/stock/:module_id',
		name: 'stock',
		component: () => import(/* webpackChunkName: "about" */ '../views/Stock.vue')
	},
	{
		path: '/bclink/:module_id',
		name: 'bclink',
		component: () => import(/* webpackChunkName: "about" */ '../views/BcLink.vue')
	},

	{
		path: '/prices',
		name: 'price list',
		component: () => import(/* webpackChunkName: "about" */ '../views/PriceList.vue')
	},
	{
		path: '/admin',
		name: 'admin',
		component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue')
	}

]

const router = new VueRouter({
	routes
})

export default router
