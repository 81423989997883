<template>
  <div class="text-center">
	<v-dialog
	  v-model="dialog"
	  persistent
	  width="500"
	>
	  <v-card>
		<v-card-title class="headline grey lighten-2">
		  {{$store.state.dialog.heading}}
		</v-card-title>

		<v-card-text class="mt-5">
		  {{$store.state.dialog.message}}
		</v-card-text>

		<v-divider></v-divider>

		<v-card-actions>
		  <v-spacer></v-spacer>
		  <v-btn
			color="primary"
			text
			@click="$store.commit('closeDialog')"
		  >
			OK
		  </v-btn>
		</v-card-actions>
	  </v-card>
	</v-dialog>
  </div>
</template>


<script>
  export default {
	computed: {
		dialog: function () {
			return this.$store.state.dialog.visible
		}
	},
  }
</script>